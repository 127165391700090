@import "reset";
@import "~fancybox/dist/css/jquery.fancybox.css";

@font-face {
  font-family: "droidsans";
  src: url("../polices/droidsans.eot");
}
@font-face {
  font-family: "droidsans";
  src: url("../polices/droidsans.ttf") format("truetype");
}

.eurostile {
  font-family: "droidsans", Arial, sans-serif;
}

body {
  background: #fff;
  font-size: 13px;
  padding-bottom: 90px;
  padding-bottom: 0;
}

h1,
h2 {
  font-family: "droidsans", Arial, sans-serif;
}

h2 {
  color: #3b3b3b;
  font-size: 2.3em;
  margin-bottom: 15px;
}
#contenu #recap #financement h2,
#contenu #recap #financement legend {
  color: #3b3b3b;
}

fieldset {
  background: rgba(255, 255, 255, 0.8);
  border: 1px solid #dadada;
  border-radius: 5px;
  margin-top: 25px;
  padding: 1%;
}
#recap fieldset {
  border-color: #dadada;
}

#recap .infinity-block fieldset,
#recap .guaranties-choice-block fieldset {
  padding: 0;
  margin: 0;
  border: 0;
  background: none;
}
#itroom_boulangersimulationbundle_financement_infinity label,
#recap .guaranties-choice-block .garanties-label {
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
  color: black;
}

#recap #itroom_boulangersimulationbundle_financement_infinity {
  margin: 0;
}

legend {
  color: #3b3b3b;
  font-weight: bold;
  font-size: 15px;
  padding: 0 3px;
}

#top {
  background: #3b3b3b;
}

.itroom_boulanger_simulation_recapitulatif #ariane .solution {
  background: #ff5c01 url(/build/images/ariane-noir.25f30d88.png) no-repeat left
    center;
}

#contenu .centre {
  background: url(../images/ombre-logo.jpg) no-repeat;
  padding-top: 30px;
}

.centre {
  position: relative;
  display: block;
  width: 980px;
  margin: 0 auto;
}

.clear {
  clear: both;
}

#logo,
#ariane {
  display: inline-block;
  color: #fff;
  font-family: "droidsans", Arial, sans-serif;
  vertical-align: top;
}

#logo img {
  vertical-align: top;
}

#ariane {
  font-size: 20px;
  line-height: 76px;
}

#ariane a,
#ariane a:visited,
#ariane span {
  color: #fff;
  padding: 0 38px;
  display: inline-block;
}

#ariane .panier {
  padding-left: 0;
}

.itroom_boulanger_simulation_front_solution #ariane .solution,
.itroom_boulanger_simulation_front_homepage #ariane .budget,
.itroom_boulanger_simulation_homepage #ariane .budget {
  background: #ff5c01 url(../images/ariane-noir.png) no-repeat left center;
}

.itroom_boulanger_simulation_front_homepage #ariane .solution,
.itroom_boulanger_simulation_homepage #ariane .solution {
  background: url(../images/ariane-orange.png) no-repeat left center;
}

#mentions-completes {
  margin-top: 50px;
  padding: 20px;
  color: #222;
}

#footer {
  /*position: fixed;*/
  margin-top: 90px;
  bottom: 0;
  text-align: center;
  width: 100%;
}

#footer .cms {
  background: #3b3b3b;
  width: 980px;
  margin: 0 auto;
  font-family: "droidsans", Arial, sans-serif;
  font-size: 14px;
  color: #fff;
}

#footer .cms a,
#footer .cms a:visited {
  color: #fff;
  line-height: 42px;
  border: 1px solid #666;
  border-width: 0 1px 0 0;
  width: 35%;
  display: inline-block;
}

#footer .cms a:not(.is_disabled):hover,
#footer .cms a:not(.is_disabled):focus,
#footer .cms a:not(.is_disabled):active {
  background: rgb(71, 152, 162);
}

#footer .cms a#cms_pop_avantage_carte_b_plus,
#footer .cms a:last-child {
  border-width: 0;
}

#footer .acces-hotesse {
  background: #fff;
  line-height: 25px;
  color: #2d2d2d;
  font-size: 10px;
  display: block;
}

#footer .acces-hotesse,
#footer .cms {
  position: relative;
  z-index: 900;
}

#footer .acces-hotesse span {
  text-decoration: underline;
  margin-left: 10px;
}

.valider,
#offres .select .valider,
#offres .imprimer {
  font-family: "droidsans", Arial, sans-serif;
  background: #ff5c00;
  border: 1px solid #cc4a00;
  color: #fff;
  font-weight: normal;
  border-radius: 4px;
}
#offres .imprimer {
  background: #64a6d8;
  border-color: #3379ad;
  display: none;
  text-align: center;
  position: relative;
  width: 92%;
  font-size: 17px;
  margin: 10px auto 0;
  padding: 6px 0;
}
#offres .select .imprimer {
  display: block;
}

#economies {
  background: #3d8fd0;
  padding: 2.7% 2% 0.8%;
  margin-right: 33px;
  width: 63%;
  color: #fff;
  vertical-align: top;
  display: inline-block;
  position: relative;
  margin-top: 5px;
}

#economies h3 {
  position: absolute;
  left: -23px;
  top: -55px;
}

#economies p {
  margin-bottom: 0;
}

#economies .mentions {
  font-size: 11px;
  font-style: italic;
  margin: 10px 0 0;
}

.cheque {
  background: url(../images/faux-cheque-grand.jpg) no-repeat top;
  text-align: center;
  line-height: 80px;
  font-size: 18px;
  color: #131313;
  font-weight: bold;
  white-space: nowrap;
  width: 80px;
  height: 80px;
  padding: 20px 8px 10px 154px;
  border: 1px solid #1b5685;
  margin-left: 20px;
  position: relative;
  top: -7px;
  float: right;
  span {
    position: relative;
    right: 18px;
    font-size: 16px;
  }
}

#recap {
  background: #f6f6f6;
  border: 1px solid #dadada;
  margin: 0 20px 30px;
}

#recap .montant {
  background: #ff5c01;
  color: #fff;
  text-align: center;
  font-weight: bold;

  padding: 5px;
  display: inline-block;
  vertical-align: top;
  width: 175px;
  height: 63px;
  margin: 0;
}

#recap .montant span {
  font-weight: normal;
  font-size: 43px;
  font-family: "droidsans", Arial, sans-serif;
  line-height: 44px;

  display: block;
}

#recap .donnees {
  display: inline-block;
  vertical-align: top;
  height: 63px;
  width: 680px;
  padding: 5px 5px 5px 20px;
  margin: 0;

  background: url(../images/fleche.png) no-repeat left center;

  font-weight: bold;
  color: #595959;
}

#recap .modifier {
  padding-top: 20px;
  cursor: pointer;
}
#recap form .form-achat {
  margin-bottom: 40px;
}
#recap form .form-achat div.box {
  margin-right: 27px;
}

#recap .options-financement {
  margin-bottom: 25px;
  margin-top: 60px;
  position: relative;
}
.form-achat #apportinit {
  display: inline;
  position: relative;
}
#infos,
#sliders {
  width: 413px;
  padding: 0 20px;
  display: inline-block;
  vertical-align: top;
}

#sliders h2 {
  margin-bottom: 30px;
}
#infos {
  margin-left: 20px;
  color: #595959;
}

#infos h2 {
  margin-bottom: 70px;
}

#infos h3 {
  font-size: 35px;
  color: #ff5c01;
  font-family: "droidsans", Arial, sans-serif;
  margin: 25px 0 30px;
}

#infos ul,
#infos li,
.offre ul,
.offre li {
  list-style: none;
  margin: 0;
  padding: 0;
}

#infos li,
.offre li {
  padding-left: 25px;
  background: url(../images/coche-argument.png) no-repeat left 4px;
  margin-bottom: 7px;
}

#infos li strong {
  color: #ff5c01;
  font-weight: bold;
  font-size: 17px;
}

#infos .mentions {
  font-size: 11px;
  margin-top: 20px;
}

#mentions1 {
  text-align: center;
  color: #666;
  padding-top: 20px;
}

#diagrammes {
  width: 483px;
  display: inline-block;
}

.nombrediagrammes2 {
  padding-top: 60px;
}

#diagrammes .reparation,
#diagrammes .remboursement,
#diagrammes .infinity {
  position: relative;
}

#diagramme-reparation,
#diagramme-remboursement,
#diagramme-infinity,
#alerte-reparation,
#alerte-remboursement {
  width: 99%;
  height: 150px;
  padding-right: 1%;
  border: 1px solid #000;
  background: #fafafa center center no-repeat;
}

#alerte-reparation,
#alerte-remboursement {
  padding: 2% 2.5%;
  width: 95%;
  height: 120px;
}

#diagramme-reparation,
#diagramme-infinity,
#alerte-reparation {
  /*background: #37a2db;*/
  border-color: #4590b0;
  margin-bottom: 20px;
}

#diagramme-remboursement,
#alerte-remboursement {
  /*background: #a8dc65;*/
  border-color: #7db73e;
}

#alerte-reparation h3,
#alerte-remboursement h3 {
  font-size: 16px;
  font-weight: bold;
}

#alerte-reparation h3 {
  color: #37a2db;
}

#alerte-remboursement h3 {
  color: #7db73e;
}

#alerte-reparation p,
#alerte-remboursement p {
  font-weight: bold;
  color: #555;
  text-align: center;
  padding-top: 15px;
}

#offres {
  padding: 40px 50px 0;
}

.offre {
  display: inline-block;
  border: 1px solid #dadada;
  border-radius: 10px;
  vertical-align: top;
  width: 30%;
  margin-left: 4%;
  background: #fff center 60px no-repeat;
  padding-bottom: 15px;
  cursor: pointer;
  -moz-user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.offre:first-child {
  margin-left: 0;
}

.colonne1 .offre,
.colonne1 .offre:first-child {
  margin: 0 35%;
  position: relative;
}

.colonne2 .offre,
.colonne2 .offre:first-child {
  margin-left: 16%;
}

.offre h3 {
  background: url(../images/sprite-check.png) no-repeat 8px 16px;
  font-family: "droidsans", Arial, sans-serif;
  font-size: 18px;
  border-bottom: 1px solid #dadada;
  color: #fff;
  border-radius: 10px 10px 0 0;
  padding: 8px 0 8px 37px;
  margin-bottom: 5px;
  cursor: pointer;
}

.offre h3 span {
  display: block;
  font-size: 12px;
}

.offre ul {
  margin-left: 10px;
  color: #595959;
}

.offre li {
  margin: 0;
}

.offre .credit {
  font-family: "droidsans", Arial, sans-serif;
  font-size: 40px;
  color: #595959;
  text-align: center;
  margin-bottom: 0;
  line-height: 0.7em;
  padding: 10px 0;
}

.offre .credit .inifity-mensuel {
  margin-top: 10px;
  display: block;
  span {
    font-size: 22px;
    &.libelle-infinity {
      display: block;
    }
  }
}

.offre .quatro {
  font-size: 22px;
  line-height: 1.1em;
}

.offre .credit .multiple,
.offre .credit .conteneur-suivi-de {
  font-size: 22px;
}

.offre .credit .conteneur-suivi-de {
  display: block;
}

.offre .mentions {
  display: block;
  color: #595959;
  padding: 10px;
  margin: 10px 0 0;
  line-height: 1.5em;
}
.offre .mentions a,
.offre .mentions a:visited {
  color: #595959;
}

.offre .mentions table {
  width: 100%;
}

.offre .mentions table,
.offre .mentions table tr,
.offre .mentions table td,
.offre .mentions table th {
  border: 1px solid #aaaaaa;
}

.offre .mentions table td,
.offre .mentions table th {
  padding: 4px 4px;
}

.mentions table th {
  color: #777;
  font-weight: bold;
}

.offre .mentions table td:first-child,
.offre .mentions table th:first-child {
  width: 60%;
}

.offre .cheque {
  background-image: url(../images/faux-cheque-grand.jpg);
  float: none;
  font-size: 20px;
  color: #595959;
  top: 0;
  margin: 15px 0 25px 10px;
}

.offre .valider {
  display: block;
  text-align: center;
  position: relative;
  width: 92%;
  font-size: 17px;
  margin: 0 auto;
  padding: 6px 0;

  background: #fe8c4b;
  border-color: #da7f4b;
  cursor: default;
}

.pave-achat h3 {
  color: #595959;
  padding: 15px 0 12px 37px;
}
.pave-infinity h3,
.pave-reparation h3 {
  color: #37a2db;
}

.pave-remboursement h3 {
  color: #7db73e;
}

#offres .select h3 {
  background-position: 8px -31px;
}

.select {
  border-color: #9a9a9a;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.5);
}

.select .valider {
  cursor: pointer;
}

.select .mentions {
  display: block;
}
/**
    éléments de formulaire
**/
input[type="submit"] {
  cursor: pointer;
}
#financement {
  padding: 20px 30px 0;
}

#recap #financement {
  border-top: 1px solid #dadada;
  background: #fbfbfb;
  display: none;
  padding: 20px 12px 0;
}

#financement input[type="submit"] {
  text-transform: uppercase;
  font-size: 21px;
  height: 76px;
  line-height: 76px;
  padding: 0 1.5%;
  margin-top: 5px;
}

#recap input[type="submit"] {
  float: right;
  font-size: 18px;
  height: 50px;
  line-height: 50px;
  margin-bottom: 15px;
}

label {
  font-size: 11px;
  color: #939393;
  font-weight: bold;
}

div.box {
  margin-right: 29px;
  width: 180px;
  display: inline-block;
  vertical-align: top;
  position: relative;
}

div.box:after,
#recap div.box:after {
  content: "€";
  display: inline-block;
  vertical-align: bottom;
  margin-bottom: 8px;
  margin-left: 3px;
  color: #7b7b7b;
  font-size: 24px;
  font-weight: 400;
  width: 20px;
  text-align: center;
}
.box.no-euro:after,
#recap .box.no-euro:after {
  content: "";
}

div.box label,
div.box input {
  border: 1px solid #b3b3b3;
  margin: 0;
  display: inline-block;
}

.itroom_boulangersimulationbundle_financement_garantieConstructeur label {
  font-size: 11px;
  color: #939393;
  font-weight: bold;
  margin-bottom: 0;
  padding-bottom: 0;
}

div.box input {
  box-shadow: none;
}

div.box input:focus {
  outline: none;
  box-shadow: none;
}

div.box label {
  border-radius: 4px 4px 0 0;
  border: 0;
  font-size: 11px;
  color: #939393;
  font-weight: bold;
  padding: 10px 15px 5px;
  background: transparent;
  width: 118px;
  position: absolute;
  top: 1px;
  left: 1px;
  z-index: 1;
}

div.itroom_boulangersimulationbundle_financement_montantServices label {
  padding: 10px 8px 5px;
  width: 132px;
}

#recap .form-achat .type-text.radios label {
  color: #939393;
}

div.box label.error {
  border: 0;
  display: inline;
  color: red;
  background: transparent;
  white-space: nowrap;
}

div.box input {
  border-radius: 4px;

  padding: 37px 14px 6px;
  width: 120px;

  font-size: 21px;
  color: #3b3b3b;
}
#validation h2 {
  margin: 10px 0 30px;
}
#validation .box {
  background: transparent;
  display: block;
  width: auto;
  margin-bottom: 20px;
}
#validation .box label {
  width: 523px;
}
#validation .box input {
  width: 525px;
}
#validation .valider {
  font-size: 23px;
  padding: 5px 10px;
  float: right;
  margin-right: 5px;
}

#validation p {
  font-size: 14px;
  color: #666;
}
.form-reparation,
.form-remboursement,
.form-service,
.form-accessoire {
  width: auto;
  display: inline-block;
  position: relative;
}

.title-garanties {
  padding-bottom: 10px;
  margin-right: 30px;
  font-family: "droidsans", Arial, sans-serif;
}

.title {
  font-family: "droidsans", Arial, sans-serif;
  font-size: 2.2em;
  font-weight: lighter;
}

fieldset div.box {
  margin-right: 14px;
}
fieldset div.box:after,
#recap fieldset div.box:after {
  width: 20px;
}

.form-achat {
  margin-bottom: 70px;
}

.form-reparation,
.form-remboursement,
.form-service,
.form-accessoire {
  margin-right: 5.5%;
  background: rgba(255, 255, 255, 0.8);
  border: 1px solid #dadada;
  border-radius: 5px;
  margin-top: 25px;
  padding: 1%;
}

.form-reparation legend,
.form-remboursement legend,
.form-service legend,
.form-accessoire legend {
  position: absolute;
  top: -40px;
  border-bottom: none;
  padding-bottom: 15px;
  font-family: "droidsans", Arial, sans-serif;
  font-size: 0.98em;
}

.legend-garanties {
  margin-bottom: -20px;
  padding-left: 10px;
  font-size: 1em;
}

.options-financement {
  margin-top: 67px;
  margin-bottom: 70px;
  background: #f5f5f5;
  border-color: #dadada;
  padding: 1% 2%;
  padding-top: 53px;
  border: 1px solid #dadada;
  border-radius: 5px;
}

.options-financement img {
  position: absolute;
  margin-left: -43px;
  margin-top: -95px;
}
.options-financement div label {
  display: none;
}

.options-financement div div label {
  display: inline-block;
  color: #5e5e5e;
  font-weight: bold;
  font-size: 12px;
  margin-right: 36px;
  cursor: pointer;
  padding-left: 6px;
  line-height: 30px;
  vertical-align: middle;
}

.options-financement div div label:first-letter {
  text-transform: capitalize;
}

.options-financement div div .checkbox {
  padding: 0;
}

.options-financement div div label:last-child {
  margin-right: 0;
}

/*boutons radios*/
.radios {
  display: inline-block;
}

.type-text.radios label {
  display: block;
}
.type-text.radios .radios label {
  display: inherit;
}
.type-text.radios:after,
#recap .type-text.radios:after {
  content: "mois";
  display: inline-block;
  color: #7b7b7b;
  width: 50px;
  text-align: center;
  font-size: 18px;
}
.fake-radios {
  border: 1px solid #999;
  border-radius: 4px;
  margin-top: 3px;
  display: inline-block;
}

.fake-radios .bouton-radio {
  cursor: pointer;
  display: inline-block;
  background: #dbdbdb;

  border-right: 1px solid #c1c1c1;

  padding: 10px 11px;
  width: 23px;

  font-size: 21px;
  color: #6b6b6b;
  text-align: center;

  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.fake-radios .bouton-radio:hover {
  color: #444;
  background: #eee;
}

.fake-radios .check,
.fake-radios .check:hover {
  background: #fff url(../images/radio-actif.png) repeat-x bottom left;
  color: #6b6b6b;
}

.fake-radios .bouton-radio:first-child {
  border-radius: 4px 0 0 4px;
}

.fake-radios .bouton-radio:last-child {
  border-radius: 0 4px 4px 0;
  border-right: 0;
}

.checkbox {
  height: 18px;
  width: 17px;
  display: inline-block;

  position: relative;
  top: 2px;

  background: url(../images/sprite-check.png) no-repeat left 2px;

  cursor: pointer;
}

.checkbox.check {
  background-position: left -45px;
}

/*fin boutons radios*/

#validation {
  display: none;
}

#fancybox-content #validation {
  display: block;
}
/**
   fin éléments de formulaire
**/

/**
* popin
**/
.details {
  cursor: pointer;
  position: absolute;
  right: -13px;
  top: -5px;
}

.details-petit {
}

.popin {
  display: none;
}

#fancybox-content h3 {
  font-family: "droidsans", Arial, sans-serif;
  color: #fff;
  background: #ff5c01;
  padding: 6px 10px;
  margin: 20px 0 10px;
}

#fancybox-content .alert-error {
  background: url(../images/warning.png) no-repeat;
  padding: 10px 30px 10px 60px;
  font-size: 14px;
  color: red;
  font-weight: bold;
  margin: 30px;
}

#contenu #concours {
  display: none;
}

.bravo-concours {
  display: none;
}

#concours h1 {
  color: #ff5c00;
  /*    background: url(../images/ariane-blanc.png) no-repeat left center #FF5C00;
      padding: 15px;*/
}

#concours a,
#concours a:visited {
  text-decoration: underline;
}
#concours a.valider,
#concours a.valider:visited {
  text-decoration: none;
}

#concours h2 span {
  color: #ff5c00;
  font-size: 0.7em;
}

.photo {
  float: left;
  margin: 0;
  color: #666;
  display: block;
}

#concours .gains {
  margin-bottom: 10px;
  float: left;
  width: 550px;
}

#concours .gains h2 {
  margin-bottom: 20px;
}

#concours .gains ul,
#concours .gains li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
#concours .gains li {
  padding-left: 23px;
  background: url(../images/coche-argument.png) no-repeat left 5px;
  font-family: "droidsans", Arial, sans-serif;
  font-size: 14px;
  margin-bottom: 8px;
}

#concours h2.clear {
  padding-top: 40px;
  margin-bottom: 0;
}

#concours h2.bonjour {
  padding-top: 30px;
}

#concours .ergonomique {
  position: relative;
  top: 5px;
}
#concours .complet {
  margin: 0px 0 0 150px;
}

#concours .adapte {
  margin-left: 170px;
  position: relative;
  top: -20px;
}

#concours .conditions {
  font-size: 1.05em;
}

#concours .conditions strong {
  font-size: 1.15em;
}

#concours .participer {
  border: 1px solid #dadada;
  background: #f5f5f5;
  padding: 5px 10px;
  margin-top: 0px;
  margin-bottom: 10px;
}

#concours .participer h2 {
  margin-bottom: 5px;
  font-size: 1.9em;
}

#concours .participer p {
  margin: 0;
}

#concours .participer p.go {
  float: right;
  text-align: center;
  padding-top: 15px;
}
#concours .participer p.go span,
#concours .participer p.go a {
  display: block;
}
#concours .participer .valider {
  font-size: 25px;
  padding: 10px;
  position: relative;
}

#concours .reglement {
  font-size: 10px;
  letter-spacing: -0.01em;
}

.valider.retour {
  padding: 5px;
  font-size: 15px;
}

.itroom_boulanger_simulation_front_merci h1 {
  color: #ff5c00;
}

#validation h2.bravo {
  color: #ff5c00;
  margin-bottom: 10px;
}

/**
*sliders
**/

#sliders-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ligne-mensualites,
.ligne-duree {
  height: 90px;
  margin-top: 20px;
}

.ligne-mensualites label,
.ligne-duree label {
  display: block;
  text-align: center;
  color: #3b3b3b;
  font-size: 17px;
  font-weight: bold;
}
.ligne-mensualites label span,
.ligne-duree label span {
  font-weight: normal;
}

.ligne-duree label {
  font-size: 14px;
}

.ligne-mensualites label span,
.ligne-duree label span {
  font-weight: normal;
}

.ligne-mensualites .content_slid {
  height: 8px;
}

.ligne-duree {
  padding-left: 11%;
}
.ligne-duree .content_slid {
  height: 4px;
  width: 60%;
}

.chargement-mentions #diagramme-reparation,
.chargement-mentions #diagramme-remboursement,
.chargement-mentions .offre {
  background-image: url(../images/loader.gif);
}

/****** surcouche jquery ui *****/

.ui-slider-handle {
  height: 45px;
  margin-left: -47px;
  width: 95px;
  padding-top: 18px;
  position: absolute;
  z-index: 600;
  cursor: pointer;
}

.infos {
  background: url("../images/curseur.png") no-repeat scroll center top
    transparent;
  color: #000;
  font-weight: bold;
  height: 45px;
  /*line-height: 18px;*/
  padding-left: 0;
  padding-top: 18px;
  padding-bottom: 18px;
  position: absolute;
  text-align: center;
  top: 2px;
  width: 95px;
  z-index: 500;
  font-size: 18px;
  -moz-user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}

.infos-active {
  background-position: center -98px;
  color: #fff;
}

.infos span {
  display: block;
  font-weight: normal;
  font-size: 13px;
  line-height: 7px;
}

.ligne-duree .ui-slider-handle {
  margin-left: -37px;
}
.ligne-duree .ui-slider-handle,
.ligne-duree .infos {
  width: 74px;
  top: 0px;
}

.ligne-duree .infos {
  background-image: url("../images/curseur-petit.png");
  height: 20px;
  padding-top: 11px;
  padding-bottom: 11px;
  font-size: 16px;
  line-height: 16px;
}
.ligne-duree .infos span {
  font-size: 12px;
}
.ligne-duree .infos-active {
  background-position: center -77px;
}

.content_slid {
  position: relative;
  width: 76%;
  background: #ff5c01;
  height: 6px;
  position: relative;
  display: inline-block;
  border-radius: 5px;
}

.echelle0,
.echelle1,
.echelle2 {
  font-weight: bold;
  display: block;
  font-size: 14px;
  position: relative;
}

.echelle1 {
  text-align: center;
}

.echelle0,
.echelle2 {
  display: inline-block;
  width: 7%;
}

.echelle0 {
  padding-right: 3%;
  text-align: right;
  width: 8.5%;
}
.echelle2 {
  padding-left: 3%;
}

input[type="submit"] {
  /* display:none;*/
}

span .cache {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 500;
}

#content {
  position: relative;
  padding-top: 1.5em;
}

#popin-background {
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1000;
}

#popin {
  width: 70%;
  margin: 2% 10% 0;
  background: #fff;
  text-align: center;
  padding: 3% 5%;
  color: #555;
  font-size: 1.2em;
  z-index: 1001;
  position: absolute;
  border: 1px solid #999;
  top: 0;
  left: 0;
}

#popin #ok {
  margin: 0 auto;
  position: relative;
  width: 30%;
  color: #fff;
  background: #67a828;
  font-size: 1.2em;
  padding: 1% 0;
}
.ui-slider-handle {
  -webkit-user-select: text; /* For Webkit */
  -khtml-user-select: text;
  -moz-user-select: text; /*  For Mozilla*/
  -o-user-select: text;
  -ms-user-select: none;
  user-select: text; /*  Default*/
}

.display-none {
  display: none !important;
}

body.loading #contenu .centre {
  background: url(../images/loader.gif) no-repeat center 300px;
}

.orange {
  color: #ff5c00;
}

/* tooltip cuisine */
.kitchen-tooltip,
.rond-tooltip {
  background: transparent url(../images/infobulle.png) no-repeat 0 0;
  height: 76px;
  width: 136px;
  padding: 30px 20px 10px 20px;
  font-size: 12px;
  line-height: 130%;
  position: absolute;
  top: 137px;
  left: 233px;
  display: none;
}
.kitchen-tooltip {
  background: transparent url(../images/infobulle.png) no-repeat 0 0;
  height: 26px;
  width: 136px;
  padding: 30px 20px 10px 20px;
  font-size: 12px;
  line-height: 130%;
  position: absolute;
  top: 45px;
  left: -10px;
  display: none;
}

.kitchen-tooltip.bottom,
.rond-tooltip.bottom {
  background: none;
  color: #c80101;
  float: right;
  font-weight: bold;
  height: 35px;
  width: 260px;
  padding: 0;
  position: relative;
  top: -50px;
  left: inherit;
}

.solution.bottom {
  position: absolute;
  top: 10px;
  left: inherit;
  right: 250px;
}
.itroom_boulanger_simulation_front_solution .solution-actions {
  position: relative;
}
#financement input[type="submit"].disabled {
  background: #c0c0c0;
  border-color: #a3a3a3;
  cursor: default;
}

.kitchen-tooltip + .alert-error {
  position: absolute;
  left: 30px;
  color: red;
}

/************/
/*  POPPIN  */
/************/
.clear {
  clear: both;
}
#piecesJustificative {
  width: 760px;
  margin: 20px auto;
}
#piecesJustificative .error {
  background: url(../images/warning.png) no-repeat;
  padding: 10px 30px 10px 60px;
  font-size: 14px;
  color: red;
  font-weight: bold;
  margin: 30px;
}
#piecesJustificative > h2 {
  padding-left: 20px;
}
#piecesJustificative .form-achat {
  padding: 20px;
  margin-bottom: 0;
}
#piecesJustificative .mesAchats {
  float: left;
  width: 74%;
  border-right: 1px solid #dadada;
}
#piecesJustificative .mesAchats .form-achat {
  padding: 0;
}
#piecesJustificative .mesAchats .form-achat > div {
  margin-right: 5px;
}
#piecesJustificative > .form-group {
  margin-bottom: 40px;
  padding: 20px;
}
#piecesJustificative .client {
  float: right;
  width: 24%;
  padding: 0;
}
#piecesJustificative .client label,
#piecesJustificative .piecesJustificative label {
  display: inline-block;
  color: #5e5e5e;
  font-weight: bold;
  font-size: 14px;
  margin-right: 36px;
  cursor: pointer;
  padding-left: 6px;
  line-height: 30px;
  cursor: pointer;
}
#piecesJustificative .piecesJustificative {
  margin-bottom: 0;
  background: #f5f5f5;
  border: 1px solid #dadada;
  border-radius: 5px;
}
#piecesJustificative input[type="radio"] {
  position: absolute;
  left: -99999px;
}
#piecesJustificative .cloneAchat label {
  border-radius: 4px 4px 0 0;
  border-width: 1px 1px 0;
  font-size: 11px;
  color: #939393;
  font-weight: bold;
  padding: 10px 15px 5px;
  background: #fff;
  width: 118px;
}
#piecesJustificative
  .cloneAchat
  .itroom_boulangersimulationbundle_financement_montantServices
  label {
  padding: 10px 8px 5px;
  width: 132px;
}
#piecesJustificative
  .cloneAchat
  .itroom_boulangersimulationbundle_financement_garantieConstructeur
  label {
  padding: 0;
}
#piecesJustificative .form-group .form-group {
  position: relative;
  float: left;
  width: 50%;
  margin-bottom: 20px;
}
#piecesJustificative .form-group .form-group label {
  margin-right: 10px;
}
#piecesJustificative .piecesJustificative .form-group ul {
  list-style-type: none;
  margin: 0;
}
#piecesJustificative .piecesJustificative .form-group ul li {
  position: relative;
  margin: 10px 0;
  padding-left: 30px;
}
#piecesJustificative .piecesJustificative .form-group ul li.or span {
  padding-left: 50px;
}
#piecesJustificative
  .piecesJustificative
  .form-group
  ul
  li
  .fa-exclamation-circle {
  color: #ff2423;
  font-size: 18px;
  margin-right: 5px;
  vertical-align: bottom;
}
#piecesJustificative .moreInfo {
  color: #60aeb8;
  font-size: 16px;
  font-weight: 700;
  background: #fff;
  width: 20px;
  height: 20px;
  line-height: 20px;
  display: inline-block;
  border-radius: 50%;
  border: 1px solid #cdcdcd;
  text-align: center;
  cursor: pointer;
}
#piecesJustificative .form-group .tooltip {
  display: none;
  position: absolute;
  top: -100px;
  left: -25%;
  width: 100%;
  background: #fff;
  border: 2px solid #60aeb8;
  border-radius: 6px;
  padding: 10px;
  opacity: 0;
  -moz-box-shadow: 1px 1px 4px 0px #656565;
  -webkit-box-shadow: 1px 1px 4px 0px #656565;
  -o-box-shadow: 1px 1px 4px 0px #656565;
  box-shadow: 1px 1px 4px 0px #656565;
  transition: all ease 0.3s;
  z-index: 100;
}
#piecesJustificative .form-group .revenus.tooltip {
  top: -185px;
}
#piecesJustificative .form-group .carteBancaire.tooltip {
  top: -170px;
}
#piecesJustificative .form-group .rib.tooltip {
  top: -70px;
}
#piecesJustificative .form-group .rib.tooltip p:last-child {
  display: none;
}
#piecesJustificative .form-group .rib.tooltip.superieur p:last-child {
  display: block;
}
#piecesJustificative .form-group ul li .tooltip {
  display: none;
  position: absolute;
  left: 25%;
  width: 100%;
}
#piecesJustificative .form-group .tooltip img {
  max-width: 100%;
  width: 30%;
}
#piecesJustificative .form-group .tooltip.activ {
  display: block;
  opacity: 1;
  transition: all ease 0.3s;
}
#piecesJustificative .form-group .tooltip.activ .closeTooltip {
  float: right;
  font-size: 16px;
  border: 1px solid #000;
  width: 20px;
  height: 20px;
  line-height: 20px;
  border-radius: 50%;
  text-align: center;
  background: #000;
  color: #fff;
  cursor: pointer;
}
#piecesJustificative .form-group .tooltip ul {
  list-style-type: disc;
}
#piecesJustificative .form-group .tooltip ul li {
  padding-left: 0;
  margin-left: 15px;
}
#piecesJustificative .client .center {
  text-align: center;
}
#piecesJustificative .client .center .btn-close {
  display: inline-block;
  padding: 5px 12px;
  margin: 20px 0;
  font-size: 14px;
  background-color: #ff5c00;
  color: #fff;
  font-weight: 700;
  border: 1px solid #ff5c00;
  border-radius: 6px;
  cursor: pointer;
}
#piecesJustificative .client .center .btn-close:hover,
#piecesJustificative .client .center .btn-close:active,
#piecesJustificative .client .center .btn-close:focus {
  background-color: transparent;
  color: #ff5c00;
}
#piecesJustificative .piecesJustificative .allCheckBox {
  display: none;
  text-align: center;
}
#piecesJustificative .piecesJustificative .allCheckBox p {
  margin-bottom: 10px;
  font-weight: 700;
  color: #5cb85c;
}
#piecesJustificative .piecesJustificative .allCheckBox .btn-continue {
  display: inline-block;
  padding: 5px 12px;
  margin: 20px 0;
  font-size: 14px;
  background-color: #5cb85c;
  color: #fff;
  font-weight: 700;
  border: 1px solid #5cb85c;
  border-radius: 6px;
  cursor: pointer;
}
#piecesJustificative .piecesJustificative .allCheckBox .btn-continue:hover,
#piecesJustificative .piecesJustificative .allCheckBox .btn-continue:active,
#piecesJustificative .piecesJustificative .allCheckBox .btn-continue:focus {
  background-color: transparent;
  color: #5cb85c;
}

/* Pages erreur */
.page_error footer {
  display: none;
}

#message_erreur {
  margin: 60px auto 30px;
  width: 67%;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  color: #3b3b3b;
}
.error_text {
  font-size: 3.3em;
  margin-bottom: 0;
}
.button-error {
  text-align: right;
}
.button-error-403 {
  text-align: right;
  margin-right: 44px;
}
.button-error-500 {
  margin-right: 0;
  text-align: right;
}
.button_back {
  font-size: 2.2em;
  background-color: transparent;
  border-color: transparent;
  cursor: pointer;
  text-decoration: underline;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  color: #3b3b3b;
}
.button_back:hover {
  font-size: 2.3em;
}
.numero_error {
  font-size: 18em;
  line-height: 0.7em;
  margin-left: 10px;
  font-weight: 800;
  margin-bottom: 22px;
}
.error_text_find {
  font-size: 2.7em;
  margin-bottom: 20px;
}
.error_text_find_500 {
  font-size: 2.7em;
  margin-bottom: 20px;
  text-align: right;
}
.fa-arrow-left:before {
  position: relative;
  right: 13px;
}
.fa {
  width: 50px;
  text-align: right;
}

.guaranties-choice-block {
  display: flex;
  justify-content: flex-start;
}

.guaranties-choice-block fieldset {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.garanties-check .checkbox {
  padding-left: 0;
}

.garanties-check {
  display: flex;
  align-items: baseline;
  margin: 10px 35px 10px 0;
}

.garanties-label {
  padding-left: 10px;
  font-family: "droidsans", Arial, sans-serif;
}

#itroom_boulangersimulationbundle_financement_infinity {
  display: flex;
  justify-content: center;
  align-items: baseline;
  margin-top: 30px;
  border: 1px solid #dadada;
  border-radius: 5px;
  padding: 1%;
}

#itroom_boulangersimulationbundle_financement_infinity input {
  margin-right: 20px;
}

#itroom_boulangersimulationbundle_financement_infinity label {
  margin-right: 90px;
  font-family: "droidsans", Arial, sans-serif;
}

.CEG-block {
  margin-top: 30px;
}

.CEG-block.home {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.CEG-block.recap {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.mon-produit-mensuel::before {
  content: "Soit";
  position: absolute;
  z-index: 9999;
  left: 45%;
  font-weight: 800;
  font-size: 2.3em;
}

.mon-service-mensuel::before, 
.mon-accessoire-mensuel::before {
  content: "+";
  position: absolute;
  z-index: 9999;
  left: 45%;
  font-weight: 800;
  font-size: 5.3em;
  padding-bottom: 20px;
  color: #f55d0a;
}

.solution-h2 {
  background-color: #f55d0a;
  color: #fff;
}

.ma-solution-mensuelle::before {
  content:"=";
  position: absolute;
  z-index: 9999;
  left: 45%;
  font-weight: 800;
  font-size: 5.3em;
  padding-left: 10px;
  color: #f55d0a;
}

.mon-produit-mensuel,
.mon-service-mensuel,
.mon-accessoire-mensuel,
.ma-solution-mensuelle {
  display: flex;
  align-items: center;
}

.mon-produit-mensuel div,
.mon-service-mensuel div,
.mon-accessoire-mensuel div,
.ma-solution-mensuelle div {
  text-align: center;
  height: 61px;
  display: flex;
  align-items: center;
}

.budget-mensuel-list {
  display: flex;
  flex-direction: column;
  height: 90%;
  justify-content: space-around;
  align-items: center;
}

.budget p {
  font-size: 2.3em;
  font-weight: 800;
  color: #f55d0a;
  margin: 0;
}

.budget.budget-total p {
  background-color: #f55d0a;
  color: #fff;
  padding: 0px 15px;
}

.per-month {
  color: #3b3b3b;
}

.budget-mensuel {
  background: #f3f3f3;
  margin-left: 20px;
  h2 {
    text-align: center;
  }
}

#budget_mensuel_montant_mensualite,
#monServiceCalc,
#monAccessoireCalc,
#total {
  font-size: 1.3em;
}

.itroom_boulangersimulationbundle_financement_dureeGarantieReparation label,
.itroom_boulangersimulationbundle_financement_dureeGarantieRemboursement label {
  font-size: 11px;
  color: #939393;
  font-weight: bold;
  margin-bottom: 0;
  padding-bottom: 0;
}

#itroom_boulangersimulationbundle_financement_opcs {
  display: flex;
  justify-content: space-between;
}

.checkboxes {
  padding-bottom: 0px !important;
}

#itroom_boulangersimulationbundle_financement_infinity {
  display: flex;
  align-items: center;
}

#itroom_boulangersimulationbundle_financement_infinity .radio-container {
  display: flex;
  justify-content: center;
  align-items: start;
}

.fancybox-inner h3 {
  font-family: "droidsans", Arial, sans-serif;
  color: #fff;
  background: #ff5c01;
  padding: 0px 10px;
  font-size: 1.3em;
  line-height: 1.5;
}

.fancybox-inner {
  height: 500px;
}

.fancybox-wrap.fancybox-desktop.fancybox-type-html.fancybox-opened {
  width: 700px;
  height: 500px;
}

.fancybox-overlay
  .fancybox-wrap
  .fancybox-skin
  .fancybox-outer
  .fancybox-inner {
  width: 700px !important;
  height: 500px;
  overflow: auto;
}

.fancybox-overlay .fancybox-wrap .fancybox-skin {
  width: 700px !important;
  height: auto;
}

.fancybox-inner ol li {
  font-size: 13px;
}

.fancybox-outer {
  position: relative;
  width: 100%;
  height: 100%;
  background: #fff;
}

.fancybox-wrap .fancybox-skin .fancybox-outer .fancybox-inner .alert-error {
  background: url(../images/warning.png) no-repeat;
  padding: 10px 30px 10px 60px;
  font-size: 14px;
  color: red;
  font-weight: bold;
  margin: 30px;
}

.soluce-budget {
  margin-top: 30px;
}

.soluce-btn {
  border-color: rgba(128, 128, 128, 0.554);
  color: #ff5a01b1;
  background-color: white;
  pointer-events: none;
}

.infinity-block fieldset {
  border: none;
}

.garanties-form
  .infinity-block
  fieldset
  .itroom_boulangersimulationbundle_financement_infinity
  #itroom_boulangersimulationbundle_financement_infinity
  .radio-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
